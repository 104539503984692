'use client';

import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import {
  type FC,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

interface ModalContextProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

type Props = {
  children: ReactNode;
  openBtn: ReactNode;
};

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: FC<Props> = (props) => {
  const { children, openBtn } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <ModalContext.Provider value={{ isOpen, onOpen, onClose }}>
      {openBtn}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { none: '100%', md: 'fit-content' },
              minWidth: { none: undefined, md: '30rem' },
              maxWidth: '90vw',
              maxHeight: '90vh',
              overflow: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: '4px',
              p: 4,
            }}
            component="article"
          >
            <Box component="div" position="relative">
              <Box
                component="div"
                position="absolute"
                top={0}
                right={0}
                zIndex={10}
                sx={{ transform: 'translate(50%, -50%)' }}
              >
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {children}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
